import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { DropdownSingle, ToggleSwitch } from 'components/FormFields'
import { Row, SkeletonText } from 'components/styledComponents'
import { DropdownStructure } from 'components/utils/types'
import { useCalculateQuotation } from 'hooks/useCalculateQuotation'
import { isNull } from 'lodash'
import { PlatformType } from 'pages/Machinery/utils/enums'
import { formatValueToMoney } from 'pages/Machinery/utils/libs'
import { MachineDetailsData, QuotationDetails } from 'pages/Machinery/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMachineQuotationData, getQuotationDestinations } from 'redux/actions/quotations'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import {
  GetQuotationDestinationsParams,
  GetQuotationDestinationsResponse,
  GetQuotationParams,
  GetQuotationResponse,
  QuotationValues,
} from 'redux/utils/quotation.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  detailsData?: MachineDetailsData
  quotation?: GetQuotationResponse[]
  quotationLoading?: boolean
  quotationDestinations?: GetQuotationDestinationsResponse
  quotationDestinationsLoading?: boolean
  dispatchGetMachineQuotationData: (params: GetQuotationParams) => void
  dispatchGetQuotationDestinations: (params: GetQuotationDestinationsParams) => void
  onQuotationChange: (newQuotationValues: QuotationValues | null, newQuotationDetails: QuotationDetails[]) => void
}

const UnconnectedQuotation: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  language,
  detailsData,
  quotation,
  quotationLoading = false,
  quotationDestinations,
  quotationDestinationsLoading = false,
  dispatchGetMachineQuotationData,
  dispatchGetQuotationDestinations,
  onQuotationChange,
}) => {
  const [destinationsOptions, setDestinationsOptions] = useState<DropdownStructure[]>([])
  const [destinationStateId, setDestinationStateId] = useState<number>(476)
  const [destinationName, setDestinationName] = useState<string>('Florida, Miami')
  const [shippingInsurance, setShippingInsurance] = useState<boolean>(false)
  const [platformType, setPlatformType] = useState<string>(PlatformType.flat)
  const [platformName, setPlatformName] = useState<string>(machineryDictionary.quotationPlatformFlat)
  const [quotationDetails, setQuotationDetails] = useState<QuotationDetails[]>([])

  const { isInternational } = quotation?.[0] || {}

  const detailsGridProps = {
    container: true,
    spacing: 1,
  }
  const detailsLeftWrapperProps = {
    xs: 12,
    md: 4,
  }
  const detailsRightWrapperProps = {
    xs: 12,
    md: 8,
    className: 'h-end',
  }
  const quotationGridProps = {
    container: true,
    spacing: 1,
    rowSpacing: 0,
    className: 'table-row',
  }
  const platformOptions: DropdownStructure[] = [
    {
      label: machineryDictionary.quotationPlatformFlat,
      value: PlatformType.flat,
    },
    {
      label: machineryDictionary.quotationPlatformSemi,
      value: PlatformType.semi,
    },
    {
      label: machineryDictionary.quotationPlatformLowboy,
      value: PlatformType.lowboy,
    },
  ]

  // Identify if we should show the skeleton structure (because the data is loading or there is no data) or if we can show the data.
  const showSkeleton: boolean = !quotation || quotationLoading || !quotationDestinations

  // Format the Location and the Price to be displayed.
  const origin: string = detailsData
    ? `${detailsData.state}, ${detailsData.country}`
    : ''
  const stateIdOrigin = detailsData
    ? detailsData.stateId
    : 476
  const priceValue = detailsData?.price
    ? formatValueToMoney(detailsData.price)
    : machineryDictionary.na

  /**
   * Triggers the action for Fetching a new Quotation based on the provided Destination ID.
   * @param stateIdDestination The State ID of the desired destination.
   */
  const fetchQuotation = (stateIdDestination: number): void => {
    // Set the params to be sent to the API for getting the Quotation.
    const quotationParams: GetQuotationParams = {
      stateIdOrigin,
      stateIdDestination,
      language,
    }

    // Trigger the Redux action for fetching the Quotation.
    dispatchGetMachineQuotationData(quotationParams)
  }

  /**
   * Calculates the new quotation values to be displayed on the screen.
   */
  const updateQuotation = (): void => {
    const naLabel: string = machineryDictionary.na
    const typedPlatform = platformType as PlatformType
    const quotationValues: QuotationValues | null =
      useCalculateQuotation(origin, destinationName, typedPlatform, platformName, shippingInsurance, naLabel, detailsData?.price, quotation)
    const newQuotationDetails: QuotationDetails[] = []

    if (!isNull(quotationValues)) {
      newQuotationDetails.push({
        concept: machineryDictionary.quotationPrice,
        value: priceValue,
      })

      Object.entries(quotationValues).forEach(([key, value]) => {
        if (key === 'usaShipping' && isInternational) {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationFreightUSA,
            value,
          })
        } else if (key === 'usaInsurance' && shippingInsurance) {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationShippingInsurance,
            value,
          })
        } else if (key === 'mexShipping' && value !== naLabel) {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationFreightMEX,
            value,
          })
        } else if (key === 'mexFee' && value !== naLabel) {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationFee,
            value,
          })
        } else if (key === 'mexHandling' && value !== naLabel) {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationHandling,
            value,
          })
        } else if (key === 'total') {
          newQuotationDetails.push({
            concept: machineryDictionary.quotationTotal,
            value,
          })
        }
      })
    }

    if (quotationValues && !isInternational) {
      quotationValues.platform = ''
    }

    // Updates the new Quotation Values.
    setQuotationDetails(newQuotationDetails)
    onQuotationChange(quotationValues, newQuotationDetails)
  }

  /**
   * Updates the Destination State ID and the Destination Name and fetches a new Quotation Data from the server when a new State ID has been selected.
   * @param newDestinationStateId The State ID to be updated for the destination selected from the Destination Picker.
   */
  const handleDestinationChange = async(newDestinationStateId: string) => {
    const newDestinationName: string | undefined = destinationsOptions.find(destination => destination.value === newDestinationStateId)?.label

    if (newDestinationName) {
      setDestinationName(newDestinationName)
      setDestinationStateId(Number(newDestinationStateId))

      // Get the new quotation data.
      await fetchQuotation(Number(newDestinationStateId))
    }
  }

  /**
   * Updates the Platform Type and the Platform Name when a new Platform Type has been selected.
   * @param newPlatformType The selected Platform Type from the Platform picker.
   */
  const handlePlatformChange = (newPlatformType: string): void => {
    const newPlatformName: string | undefined = platformOptions.find(platform => platform.value === newPlatformType)?.label

    if (newPlatformName) {
      setPlatformName(newPlatformName)
      setPlatformType(newPlatformType)
    }
  }

  /**
   * Updates the boolean value for the Shipping Insurance based on the value provided by the user.
   * @param newStatus The new value for the Shipping Insurance selected from the Shipping Insurance Toggle Button.
   */
  const handleShippingInsuranceChange = (newStatus: boolean): void => {
    setShippingInsurance(newStatus)
  }

  useEffect(() => {
    // Set the params to be sent to the API for getting the list of Destinations for the Quotations.
    const quotationDestinationsParams: GetQuotationDestinationsParams = {
      language,
    }

    // Trigger the Redux action for fetching the list of Destinations for the Quotations.
    dispatchGetQuotationDestinations(quotationDestinationsParams)
  }, [])

  useEffect(() => {
    if (quotationDestinationsLoading) {
      return
    }

    if (quotationDestinations && detailsData && !quotation) {
      // Get the initial quotation data.
      fetchQuotation(quotationDestinations.usa[0].stateId)
    }

    if (quotationDestinations) {
      const usaDestinations: DropdownStructure[] = quotationDestinations.usa.map(usaDestination => ({
        label: usaDestination.destination,
        value: usaDestination.stateId.toString(),
      }))

      const mexDestinations: DropdownStructure[] = quotationDestinations.mex.map(mexDestination => ({
        label: mexDestination.destination,
        value: mexDestination.stateId.toString(),
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.quotationDestinationToUSA,
          value: '',
          isGroup: true,
        },
        ...usaDestinations,
        {
          label: machineryDictionary.quotationDestinationToMEX,
          value: '',
          isGroup: true,
        },
        ...mexDestinations,
      ]

      setDestinationName(newOptions[1].label)
      setDestinationsOptions(newOptions)
    }
  }, [detailsData, quotationDestinations, quotationDestinationsLoading])

  useEffect(() => {
    if (!quotationLoading) {
      updateQuotation()
    }
  }, [destinationName, platformName, shippingInsurance, quotationLoading])

  return (
    <Card variant="outlined">
      <CardHeader title={machineryDictionary.quotationTitle} />
      <CardContent>
        <Grid {...detailsGridProps}>
          <Grid xs={12}>
            <Typography variant="caption">
              {machineryDictionary.quotationDescription}
            </Typography>
          </Grid>
          <Grid {...detailsLeftWrapperProps}>
            <Typography variant="button">
              {machineryDictionary.quotationOrigin}:
            </Typography>
          </Grid>
          <Grid {...detailsRightWrapperProps}>
            <Typography variant="caption">
              {showSkeleton
                ? <SkeletonText />
                : <>{origin}</>
              }
            </Typography>
          </Grid>
          <Grid {...detailsLeftWrapperProps}>
            <Typography variant="button">
              {machineryDictionary.quotationDestination}:
            </Typography>
          </Grid>
          <Grid {...detailsRightWrapperProps}>
            {showSkeleton
              ? <SkeletonText />
              : (
                  <DropdownSingle
                    id='destination'
                    options={destinationsOptions}
                    value={destinationStateId.toString()}
                    marginTop={0}
                    marginBottom={0}
                    fullWidth
                    onChange={handleDestinationChange}
                  />
                )
            }
          </Grid>
          {isInternational && (
            <>
              <Grid {...detailsLeftWrapperProps}>
                <Typography variant="button">
                  {machineryDictionary.quotationPlatformType}:
                </Typography>
              </Grid>
              <Grid {...detailsRightWrapperProps}>
                {showSkeleton
                  ? <SkeletonText />
                  : (
                      <DropdownSingle
                        id='platform'
                        options={platformOptions}
                        value={platformType}
                        fullWidth
                        onChange={handlePlatformChange}
                      />
                    )
                }
              </Grid>
            </>
          )}
          <Grid {...detailsLeftWrapperProps}>
            <Typography variant="button">
              {machineryDictionary.quotationShippingInsurance}:
            </Typography>
          </Grid>
          <Grid {...detailsRightWrapperProps}>
            {showSkeleton
              ? <SkeletonText />
              : (
                  <ToggleSwitch
                    checked={shippingInsurance}
                    onChange={handleShippingInsuranceChange}
                  />
                )
            }
          </Grid>
        </Grid>
        <Row marginTop={pxToRem(8)} marginBottom={pxToRem(8)} isColumnDirection>
          {quotationDetails.map(details => (
            <Grid {...quotationGridProps} key={details.concept}>
              <Grid {...detailsLeftWrapperProps}>
                <Typography variant="button">
                  {details.concept}:
                </Typography>
              </Grid>
              <Grid xs={12} md={8} className="h-end money">
                {showSkeleton
                  ? <SkeletonText />
                  : <>{details.value}</>
                }
              </Grid>
            </Grid>
          ))}
        </Row>
        <Grid {...detailsGridProps}>
          <Grid xs={12}>
            <Typography variant="caption">
              {machineryDictionary.quotationNote}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const mapStateToProps = ({ languageStore, quotationsStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const { quotation, quotationLoading, quotationDestinations, quotationDestinationsLoading } = quotationsStore

  return {
    dictionary,
    language,
    quotation,
    quotationLoading,
    quotationDestinations,
    quotationDestinationsLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetMachineQuotationData: (params: GetQuotationParams) => dispatch(getMachineQuotationData(params)),
  dispatchGetQuotationDestinations: (params: GetQuotationDestinationsParams) => dispatch(getQuotationDestinations(params)),
})

const Quotation: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedQuotation)

export default Quotation
