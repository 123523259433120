import {
  AccountCircle as ContactIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  PhoneIphone as MobileIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material'
import { Link, Typography } from '@mui/material'
import { LabelWithAlign, MachineMainDetailsContainer, Row, SkeletonText } from 'components/styledComponents'
import { IconFontSize, TextAlign } from 'components/utils/enums'
import { useCurrentDevice } from 'hooks'
import { MachineCarousel as Carousel } from 'pages/components/Carousel'
import { formatValueToHours } from 'pages/Machinery/utils/libs'
import { MachineDetailsData } from 'pages/Machinery/utils/types'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { WHATSAPP_NUMBER } from 'utils/constants'

type Props = {
  dictionary: LanguageDictionary
  detailsData?: MachineDetailsData
  machineDetailsLoading?: boolean
  isManagedByImcmexico?: boolean
}

const UnconnectedDetails: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  detailsData,
  machineDetailsLoading = false,
  isManagedByImcmexico = false,
}) => {
  const { isMobile, isSmartphone, isTablet } = useCurrentDevice()

  const detailsColumns: number = isSmartphone ? 1 : 2
  const mobileClass: string = isTablet ? 'tablet' : isSmartphone ? 'mobile' : ''
  const carouselHeight: number | string = isSmartphone
    ? `calc((250vw - ${pxToRem(80)})/4)`
    : (isTablet ? 296 : 376)
  const detailsRowProps = {
    groupedByTemplate: 'auto 1fr',
    gridGap: 4,
    marginBottom: pxToRem(isMobile ? 8 : 16),
  }
  const iconProps = {
    fontSize: IconFontSize.small,
  }

  const hasWhatsAppLink = isManagedByImcmexico && !!detailsData?.contactInfoMessage
  const imcmexicoMobileHref = hasWhatsAppLink
    ? detailsData.contactInfoMessage
    : `tel: ${WHATSAPP_NUMBER}`
  const imcmexicoLabel = hasWhatsAppLink
    ? machineryDictionary.contactThroughWhatsApp
    : '+52 (55)1510-8955'
  const userMobileHref = !isManagedByImcmexico && !!detailsData?.contactMobile
    ? `tel: ${detailsData.contactMobile}`
    : ''
  const mobileHref = userMobileHref || imcmexicoMobileHref
  const mobileLabel = !!userMobileHref ? detailsData?.contactMobile : imcmexicoLabel

  // Identify if we should show the skeleton structure (because the data is loading or there is no data) or if we can show the data.
  const showSkeleton: boolean = !detailsData || machineDetailsLoading

  // Format the Hours and the Location to be displayed.
  const hoursValue: string = detailsData?.hours
    ? formatValueToHours(detailsData.hours)
    : machineryDictionary.na
  const location = detailsData
    ? `${detailsData.state}, ${detailsData.country}`
    : ''

  return (
    <Row groupedByColumns={detailsColumns} gridColumnGap={0} marginBottom={pxToRem(16)}>
      <Carousel
        className={mobileClass}
        items={detailsData?.photos || []}
        height={carouselHeight}
        loading={showSkeleton}
      />
      <MachineMainDetailsContainer className={mobileClass}>
        <LabelWithAlign variant="h3" textAlign={TextAlign.center}>
          {showSkeleton
            ? <SkeletonText fontSize={pxToRem(16)} />
            : <>{detailsData?.category}</>
          }
        </LabelWithAlign>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.manufacturer}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.manufacturer}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.model}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.model}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.reference}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.reference}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.year}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.year}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.hours}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{hoursValue}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <Typography variant="button">
            {machineryDictionary.machineLocation}:
          </Typography>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{location}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <ContactIcon {...iconProps} />
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.machineContact}</>
            }
          </Typography>
        </Row>
        <Row {...detailsRowProps}>
          <PhoneIcon {...iconProps} />
          {showSkeleton
            ? <SkeletonText />
            : (
                <Link href={`tel: ${detailsData?.machineContactPhone1}`} target="_blank" variant="caption">
                  {detailsData?.machineContactPhone1.replace('+52 ', '')}
                </Link>
              )
          }
        </Row>
        {detailsData?.machineContactPhone2 && (
          <Row {...detailsRowProps}>
            <PhoneIcon {...iconProps} />
            {showSkeleton
              ? <SkeletonText />
              : (
                  <Link href={`tel: ${detailsData.machineContactPhone2}`} target="_blank" variant="caption">
                    {detailsData.machineContactPhone2.replace('+52 ', '')}
                  </Link>
                )
            }
          </Row>
        )}
        {mobileLabel && (
          <Row {...detailsRowProps}>
            {hasWhatsAppLink ? <WhatsAppIcon {...iconProps} /> : <MobileIcon {...iconProps} />}
            {showSkeleton
              ? <SkeletonText />
              : (
                  <Link href={mobileHref} target="_blank" variant="caption">
                    {mobileLabel.replace('+52 ', '')}
                  </Link>
                )
            }
          </Row>
        )}
        {detailsData?.machineContactEmail && (
          <Row {...detailsRowProps}>
            <EmailIcon {...iconProps} />
            {showSkeleton
              ? <SkeletonText />
              : (
                  <Link href={`mail: ${detailsData.machineContactEmail}`} target="_blank" variant="caption">
                    {detailsData.machineContactEmail}
                  </Link>
                )
            }
          </Row>
        )}
      </MachineMainDetailsContainer>
    </Row>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary } = languageStore
  const { machineDetailsLoading } = machineryStore

  return {
    dictionary,
    machineDetailsLoading,
  }
}

const Details: any = connect(
  mapStateToProps,
)(UnconnectedDetails)

export default Details
