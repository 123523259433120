import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import { NotificationAlert } from 'components/FormFields'
import { SkeletonText } from 'components/styledComponents'
import { NotificationType } from 'components/utils/enums'
import { useCleanBasePath, useCurrentDevice, usePathFilters } from 'hooks'
import kebabCase from 'lodash/kebabCase'
import PageContainer from 'pages/components/PageContainer'
import ActionButtons from 'pages/Machinery/MachineDetails/ActionButtons'
import Details from 'pages/Machinery/MachineDetails/Details'
import Quotation from 'pages/Machinery/MachineDetails/Quotation'
import { getMachineSocialMediaData } from 'pages/Machinery/utils/libs'
import { MachineDetailsData, QuotationDetails } from 'pages/Machinery/utils/types'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getCategories, getMachineDetails, getManufacturers, getModels } from 'redux/actions/machinery'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import {
  GetCategoriesParams,
  GetMachineDetailsParams,
  GetMachineDetailsResponse,
  GetManufactorersParams,
  GetModelsParams,
} from 'redux/utils/machinery.types'
import { QuotationValues } from 'redux/utils/quotation.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { BASE_PATH, DROPDOWN_UNSELECTED_OPTION, MACHINERY_CATALOG_PATH } from 'utils/constants'
import { CarouselItem, KeyValueRecord } from 'utils/types'

type Props = {
  dictionary: LanguageDictionary
  language: Language
  categoriesMap?: KeyValueRecord<string>
  manufacturersMap?: KeyValueRecord<string>
  machineDetails?: GetMachineDetailsResponse
  machinesError?: string
  dispatchGetCategories: (params: GetCategoriesParams) => void
  dispatchGetManufacturers: (params: GetManufactorersParams) => void
  dispatchGetModels: (params: GetModelsParams) => void
  dispatchGetMachineDetails: (reference: string, params: GetMachineDetailsParams) => void
}

const UnconnectedMachineDetails: FC<Props> = ({
  dictionary: {
    machinery: machineryDictionary,
  },
  language,
  categoriesMap,
  manufacturersMap,
  machineDetails,
  machinesError,
  dispatchGetCategories,
  dispatchGetManufacturers,
  dispatchGetModels,
  dispatchGetMachineDetails,
}) => {
  const { isMobile } = useCurrentDevice()
  const { pathname: dirtyPathname } = useLocation() || {}
  const pathname = useCleanBasePath(dirtyPathname)
  const [detailsData, setDetailsData] = useState<MachineDetailsData>()
  const [showError, setShowError] = useState<boolean>(false)
  const [quotationValues, setQuotationValues] = useState<QuotationValues | null>(null)
  const [quotationDetails, setQuotationDetails] = useState<QuotationDetails[]>([])
  const [ogDescription, setOGDescription] = useState<string | undefined>()
  const [ogImage, setOGImage] = useState<string | undefined>()
  const [isManagedByImcmexico, setIsManagedByImcmexico] = useState<boolean>(false)
  const { categoryFilter, manufacturerFilter, referenceFilter } = usePathFilters(pathname)

  // Identify if we should show the skeleton structure (because the data is loading or there is no data) or if we can show the data.
  const showSkeleton: boolean = !detailsData

  const handleQuotationChange = (newQuotationValues: QuotationValues | null, newQuotationDetails: QuotationDetails[]): void => {
    setQuotationValues(newQuotationValues)
    setQuotationDetails(newQuotationDetails)
  }

  /**
   * Hides the current Error Message modal.
   */
  const handleHideError = (): void => {
    setShowError(false)
  }

  useEffect(() => {
    if (referenceFilter) {
      const params: GetMachineDetailsParams = {
        language,
      }

      dispatchGetMachineDetails(referenceFilter, params)
    }

    // Set the params to be sent to the API for getting the list of "Categories".
    const categoriesParams: GetCategoriesParams = {
      basedOnMachines: true,
    }

    // Trigger the Redux action for fetching the list of Categories.
    dispatchGetCategories(categoriesParams)
  }, [])

  useEffect(() => {
    if (machineDetails) {
      const {
        managedBy,
        category,
        manufacturer,
        model,
        reference,
        year,
        hours,
        price,
        country,
        countryId,
        state,
        stateId,
        machineContact,
        machineContactPhone1,
        machineContactPhone2,
        contactMobile,
        machineContactEmail,
        description,
        photos: machinePhotos = [],
      } = machineDetails
      const machine: string = `${category} ${manufacturer} ${model}`

      const carouselItems: CarouselItem[] = machinePhotos.map((item, index) => {
        const categoryPath = kebabCase(category)
        const manufacturerPath = kebabCase(manufacturer)
        const modelPath = kebabCase(model)

        return {
          id: `${reference}-${index}`,
          title: machine,
          imagePath: item.photo,
          footer: `${machineryDictionary.reference}: ${reference}`,
          linkLabel: machineryDictionary.viewDetails,
          linkURL: `${BASE_PATH}${MACHINERY_CATALOG_PATH[language]}/${categoryPath}/${manufacturerPath}/${modelPath}/${reference}`,
        }
      })

      const {
        machineInfo,
        machineDetailsURL,
        whatsAppBaseURL,
        contactInfoMessage,
      } = getMachineSocialMediaData(language, machineryDictionary, isMobile, category, manufacturer, model, reference)

      const newDataDetails: MachineDetailsData = {
        category,
        manufacturer,
        model,
        reference,
        year,
        hours,
        price,
        country,
        countryId,
        state,
        stateId,
        machineContact,
        machineContactPhone1,
        machineContactPhone2,
        contactMobile,
        machineContactEmail,
        description,
        photos: carouselItems,
        machineInfo,
        machineDetailsURL,
        contactInfoMessage,
        whatsAppBaseURL,
      }

      setDetailsData(newDataDetails)
      setIsManagedByImcmexico(managedBy === 'IMCMEXICO')

      const newOGDescription: string = machineryDictionary.pageDescriptionDetails.replace('{MACHINE}', machine).replace('{REFERENCE}', reference)
      setOGDescription(newOGDescription)
      setOGImage(machinePhotos[0]?.photo ?? '')
    }
  }, [machineDetails]) // This will be triggered only when the "machineDetails" has changed.

  useEffect(() => {
    if (categoryFilter !== DROPDOWN_UNSELECTED_OPTION && !!categoriesMap) {
      const categoryName: string = categoriesMap[categoryFilter] || DROPDOWN_UNSELECTED_OPTION

      // Set the params to be sent to the API for getting the list of "Manufacturers" related to the selected "Category".
      const params: GetManufactorersParams = {
        categoryName,
        basedOnMachines: true,
      }

      // Trigger the Redux action for fetching the list of Manufacturers.
      dispatchGetManufacturers(params)
    }
  }, [categoryFilter, categoriesMap]) // This will be triggered when the "categoryFilter" or the "categoriesMap" have changed.

  useEffect(() => {
    if (manufacturerFilter !== DROPDOWN_UNSELECTED_OPTION && !!categoriesMap && !!manufacturersMap) {
      const categoryName: string = categoriesMap[categoryFilter] || DROPDOWN_UNSELECTED_OPTION
      const manufacturerName: string = manufacturersMap[manufacturerFilter] || DROPDOWN_UNSELECTED_OPTION

    // Set the params to be sent to the API for getting the list of "Models" related to the selected "Category" and "Manufacturer".
    const params: GetModelsParams = {
      categoryName,
      manufacturerName,
      basedOnMachines: true,
    }

    // Trigger the Redux action for fetching the list of Models.
    dispatchGetModels(params)
    }
  }, [manufacturerFilter, manufacturersMap]) // This will be triggered when the "manufacturerFilter" or the "manufacturersMap" have changed.

  return (
    <PageContainer ogDescription={ogDescription} ogImage={ogImage}>
      <Details detailsData={detailsData} isManagedByImcmexico={isManagedByImcmexico} />
      <Card variant="outlined" sx={{ mb: pxToRem(16) }}>
        <CardHeader title={machineryDictionary.description} />
        <CardContent>
          <Typography variant="caption">
            {showSkeleton
              ? <SkeletonText />
              : <>{detailsData?.description}</>
            }
          </Typography>
        </CardContent>
      </Card>
      <Quotation detailsData={detailsData} onQuotationChange={handleQuotationChange} />
      <ActionButtons showSkeleton={showSkeleton} quotationValues={quotationValues} quotationDetails={quotationDetails} />
      <NotificationAlert
        type={NotificationType.error}
        isOpen={showError}
        onHide={handleHideError}
        message={machinesError || ''}
      />
    </PageContainer>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const {
    categoriesMap,
    manufacturersMap,
    machineDetails,
    machinesError,
  } = machineryStore

  return {
    dictionary,
    language,
    categoriesMap,
    manufacturersMap,
    machineDetails,
    machinesError,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCategories: (params: GetCategoriesParams) => dispatch(getCategories(params)),
  dispatchGetManufacturers: (params: GetManufactorersParams) => dispatch(getManufacturers(params)),
  dispatchGetModels: (params: GetModelsParams) => dispatch(getModels(params)),
  dispatchGetMachineDetails: (reference: string, params: GetMachineDetailsParams) => dispatch(getMachineDetails(reference, params)),
})

const MachineDetails: any = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedMachineDetails)

export default MachineDetails
