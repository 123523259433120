import { api } from 'api'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import { showAlert, throwError } from 'redux/actions/alert'
import { Action, ActionResponseType } from 'redux/utils/enums'
import { formatApiParams, getApiHeaders, getToken } from 'redux/utils/libs'
import { ApiParams, ServerResponse } from 'redux/utils/types'
import { ErrorProps, KeyValueRecord } from 'utils/types'
import {
  CategoriesResponse,
  CreateMachineParams,
  GetCategoriesParams,
  GetCategoriesResponse,
  GetMachineDetailsParams,
  GetMachineDetailsResponse,
  GetMachinesParams,
  GetMachinesResponse,
  GetManufactorersParams,
  GetManufacturersResponse,
  GetModelsParams,
  GetModelsResponse,
  ManufacturersResponse,
  ModelsResponse,
} from 'redux/utils/machinery.types'
import { ShowAlertParams } from 'redux/utils/alerts.types'
import { NotificationType } from 'components/utils/enums'

export const getCategories = (jsonParams: GetCategoriesParams) => async (dispatch, getState) => {
  try {
    const { languageStore: { language } } = getState()

    dispatch({
      type: Action.CATEGORIES_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Categories List API.
    const serverResponse: ServerResponse<ActionResponseType.categoriesList> = (await api.get('api/machinery/categories.php', {
      params,
    })).data

    const categoriesResponse: GetCategoriesResponse = serverResponse.response
    const categoriesFullList: CategoriesResponse[] = [
      ...categoriesResponse.topCategories,
      ...categoriesResponse.categories,
    ]

    const categoriesMap: KeyValueRecord<string> = {}
    categoriesFullList.forEach(item => {
      const urlName: string = kebabCase(item.category[language])
      categoriesMap[urlName] = item.category[language]
    })

    dispatch({
      type: Action.CATEGORIES_READ,
      payload: categoriesResponse,
    })

    dispatch({
      type: Action.CATEGORIES_MAP_UPDATE,
      payload: categoriesMap,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.CATEGORIES_LOADING,
      payload: false,
    })
  }
}

export const getManufacturers = (jsonParams: GetManufactorersParams) => async (dispatch) => {
  try {
    dispatch({
      type: Action.MANUFACTURERS_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Manufacturers List API.
    const serverResponse: ServerResponse<ActionResponseType.manufacturersList> = (await api.get('api/machinery/manufacturers.php', {
      params,
    })).data

    const manufacturersResponse: GetManufacturersResponse = serverResponse.response
    const manufacturersFullList: ManufacturersResponse[] = [
      ...manufacturersResponse.topManufacturers,
      ...manufacturersResponse.manufacturers,
    ]

    const manufacturersMap: KeyValueRecord<string> = {}
    manufacturersFullList.forEach(item => {
      const urlName: string = kebabCase(item.manufacturer)
      manufacturersMap[urlName] = item.manufacturer
    })

    dispatch({
      type: Action.MANUFACTURERS_READ,
      payload: manufacturersResponse,
    })

    dispatch({
      type: Action.MANUFACTURERS_MAP_UPDATE,
      payload: manufacturersMap,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.MANUFACTURERS_LOADING,
      payload: false,
    })
  }
}

export const getModels = (jsonParams: GetModelsParams) => async dispatch => {
  try {
    dispatch({
      type: Action.MODELS_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    // Trigger the Get Models List API.
    const serverResponse: ServerResponse<ActionResponseType.modelsList> = (await api.get('api/machinery/models.php', {
      params,
    })).data

    const modelsResponse: GetModelsResponse = serverResponse.response
    const modelsFullList: ModelsResponse[] = [
      ...modelsResponse.topModels,
      ...modelsResponse.models,
    ]

    const modelsMap: KeyValueRecord<string> = {}
    modelsFullList.forEach(item => {
      const urlName: string = kebabCase(item.model)
      modelsMap[urlName] = item.model
    })

    dispatch({
      type: Action.MODELS_READ,
      payload: modelsResponse,
    })

    dispatch({
      type: Action.MODELS_MAP_UPDATE,
      payload: modelsMap,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.MODELS_LOADING,
      payload: false,
    })
  }
}

export const publishMachine = (jsonParams: CreateMachineParams, files: File[], imagesPath: string) => async (dispatch, getState) => {
  const {
    languageStore: {
      dictionary: {
        shared: sharedDictionary,
        error: errorDictionary,
        publishMachine: publishMachineDictionary,
      },
    },
  } = getState()

  try {
    dispatch({
      type: Action.MACHINE_PUBLISHING_LOADING,
      payload: true,
    })

    // Get the User's token.
    const token = getToken()

    const formData = new FormData()

    files.forEach((file, index) => {
      formData.append(`image_${index + 1}`, file)
    })

    // Upload the Pictures.
    const serverResponse: ServerResponse<ActionResponseType.uploadPhotos> = (await api.post('api/images.php', formData, {
      headers: {
        authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })).data

    const { machineId, photos } = serverResponse.response

    // Update the JSON params with the "Machine ID" and the "photos"
    const finalJsonParams: CreateMachineParams = {
      ...jsonParams,
      machineId,
      photos: photos.map(photo => `${imagesPath}/${photo}`),
    }

    // Format the API Params.
    const params: ApiParams = formatApiParams(finalJsonParams)

    // Get the headers for the Image Upload API call.
    const apiHeaders = getApiHeaders(true, token, params)

    // Create the new machine.
    await api.post('api/machinery/machines.php', params, apiHeaders)

    // Display a success message.
    const alertProps: ShowAlertParams = {
      type: NotificationType.success,
      title: sharedDictionary.congratulations,
      message: publishMachineDictionary.machinePublishedSuccess,
      buttonLabel: sharedDictionary.ok,
    }

    // Trigger the "showAlert" action for displaying the Alert modal.
    dispatch(showAlert(alertProps))
  } catch (err) {
    // Display a success message.
    const alertProps: ShowAlertParams = {
      type: NotificationType.error,
      title: errorDictionary.somethingWentWrong,
      message: publishMachineDictionary.machinePublishedError,
      buttonLabel: sharedDictionary.ok,
    }

    // Trigger the "showAlert" action for displaying the Alert modal.
    dispatch(showAlert(alertProps))
  } finally {
    dispatch({
      type: Action.MACHINE_PUBLISHING_LOADING,
      payload: false,
    })
  }
}

export const getMachines = (jsonParams: GetMachinesParams) => async (dispatch, getState) => {
  const {
    languageStore: {
      dictionary: {
        error: errorDictionary,
        machinery: machineryDictionary,
      },
    },
  } = getState()

  try {
    dispatch({
      type: Action.MACHINES_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    const serverResponse: ServerResponse<ActionResponseType.machinesList> = (await api.get('api/machinery/machines.php', {
      params,
    })).data

    if (serverResponse.error) {
      dispatch({
        type: Action.MACHINES_ERROR,
        payload: machineryDictionary[camelCase(serverResponse.error)],
      })
    } else {
      dispatch({
        type: Action.MACHINES_ERROR,
      })
    }

    const machinesResponse: GetMachinesResponse = serverResponse.response

    dispatch({
      type: Action.MACHINES_READ,
      payload: machinesResponse,
    })
  } catch (error) {
    const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
    const errorMessage = errorDictionary[errorCode] || error.message

    const errorAlertProps: ErrorProps = {
      error,
      errorConsole,
      errorStatus,
      errorMessage,
    }

    dispatch(throwError(errorAlertProps))
  } finally {
    dispatch({
      type: Action.MACHINES_LOADING,
      payload: false,
    })
  }
}

export const getMachineDetails = (reference: string, jsonParams: GetMachineDetailsParams) => async (dispatch, getState) => {
  const {
    languageStore: {
      dictionary: { error: errorDictionary },
    },
  } = getState()

  try {
    dispatch({
      type: Action.MACHINE_DETAILS_LOADING,
      payload: true,
    })

    // Format the API Params.
    const params = formatApiParams(jsonParams)

    const serverResponse: ServerResponse<ActionResponseType.machineDetails> = (await api.get(`api/machinery/machines.php/${reference}`, {
      params,
    })).data

    const machineDetailsResponse: GetMachineDetailsResponse = serverResponse.response

    dispatch({
      type: Action.MACHINE_DETAILS_READ,
      payload: machineDetailsResponse,
    })
  } catch (error) {
    const { errorCode, code: errorStatus, error: errorConsole } = error.response.data
    const errorMessage = errorDictionary[errorCode] || error.message

    const errorAlertProps: ErrorProps = {
      error,
      errorConsole,
      errorStatus,
      errorMessage,
    }

    dispatch(throwError(errorAlertProps))
  } finally {
    dispatch({
      type: Action.MACHINE_DETAILS_LOADING,
      payload: false,
    })
  }
}

export const getMachinesCarousel = () => async dispatch => {
  try {
    dispatch({
      type: Action.MACHINES_CAROUSEL_LOADING,
      payload: true,
    })

    const machinesCarousel: ServerResponse<ActionResponseType.machinesCarousel> = (await api.get('api/machinery/carousel.php')).data

    dispatch({
      type: Action.MACHINES_CAROUSEL_READ,
      payload: machinesCarousel.response
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({
      type: Action.MACHINES_CAROUSEL_LOADING,
      payload: false,
    })
  }
}